<template>
  <TSelect
    :label="label"
    class="m-0"
    :options="list"
    :value="valueSelected"
    :selectOptions="{
      internalSearch: false,
      preserveSearch: true,
      ...customOptionLabel,
      ...selectOptions,
    }"
    :normalizer="{ label: 'id', value: 'id' }"
    :placeholder="placeholder"
    :loading="loading"
    @select="select"
    @search="search"
  />
</template>
<script>
import lodash from "@/core/plugins/lodash";
import { mapGetters } from "vuex";
export default {
  props: {
    value: {
      type: [Number, String],
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      default: "Select",
    },
    selectOptions: {
      type: Object,
      require: false,
    },
    tracking: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      valueSelected: null,
      showModal: false,
    };
  },
  watch: {
    tracking: {
      immediate: true,
      handler(value) {
        if (value) {
          this.$store.dispatch("warehouse.assemble_orders.apply-query", {
            "filter[by_tracking_id]": value,
          });
          this.valueSelected = null;
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      list: "warehouse.assemble_orders.list",
      loading: "warehouse.assemble_orders.fetching",
    }),
    customOptionLabel() {
      return !this.noCustomLabel ? { "custom-label": this.customLabel } : {};
    },
  },
  methods: {
    select(value) {
      this.valueSelected = value;
      this.$emit("update:value", value.id);
      this.$emit("change", value);
    },
    search: lodash.debounce(function (param) {
      this.$store.dispatch("warehouse.assemble_orders.apply-query", {
        "filter[by_tracking_id]": this.tracking,
        "filter[id]": param,
      });
    }, 300),
    customLabel({ id, customer }) {
      return `${id} – ${customer.name}`;
    },
  },
};
</script>
