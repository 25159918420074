<template>
  <CRow class="mb-5">
    <CCol lg="3">
      <TAside>
        <CListGroup>
          <CListGroupItem>
            <FilterForBoxesNotDistrisbuted :queries.sync="query" />
          </CListGroupItem>
          <CListGroupItem
            v-for="(box, index) in boxes_available"
            :key="`${index}${box.id}`"
            :color="box_id_selected == box.id ? 'primary' : 'secondary'"
            :to="_.getAppendRouteQuery($route, { box_id: box.id })"
          >
            <SCardBox :box="box" />
          </CListGroupItem>
          <CListGroupItem v-if="!boxes_available.length">
            <TMessageNotFound />
          </CListGroupItem>
          <CListGroupItem v-if="boxes_available_loading">
            <TSpinner :loading="boxes_available_loading" />
          </CListGroupItem>
          <SButtonLoadmore store="warehouse.boxes_not_distributed_yet" />
        </CListGroup>
      </TAside>
    </CCol>
    <CCol lg="9">
      <CRow v-if="box_id_selected">
        <CCol col="12">
          <CCard>
            <CCardHeader>
              <div class="d-flex align-items-center">
                <TMessage content="Distribute" bold>
                  <template #suffix>
                    SKU {{ box_id_selected || "..." }}
                  </template>
                </TMessage>
              </div>
            </CCardHeader>
            <CCardBody>
              <TMessage content="Quantity" bold class="mb-3">
                <template #suffix
                  >:
                  <CBadge color="info">{{ box_selected.duplicate }}</CBadge>
                </template>
              </TMessage>
              <hr />
              <CRow>
                <CCol col="12" class="mb-3">
                  <SelectOrderDistribution
                    v-if="box_selected && box_selected.sfa"
                    label="Select order"
                    :value.sync="data.order_id"
                    :tracking="box_selected.sfa.tracking_id"
                  />
                </CCol>
                <CCol col="12" class="mb-3">
                  <TInputNumber
                    label="Distribution quantity"
                    :value.sync="data.quantity"
                  />
                </CCol>
              </CRow>
            </CCardBody>
            <CCardFooter class="d-flex">
              <TButton
                color="success"
                :messageOptions="{ bold: true }"
                :options="{
                  disabled:
                    !data.quantity ||
                    data.quantity > box_selected.duplicate ||
                    loading,
                }"
                icon="cil-data-transfer-down"
                class="mx-auto"
                @click="onDistribute"
                content="Distribute"
              />
            </CCardFooter>
          </CCard>
        </CCol>
      </CRow>
    </CCol>
  </CRow>
</template>

<script>
import { mapGetters } from "vuex";
import FilterForBoxesNotDistrisbuted from "../../components/FilterForBoxesNotDistrisbuted.vue";
import SelectOrderDistribution from "../../components/SelectOrderDistribution.vue";
import filterable from "../../mixins/filterable";
export default {
  components: { SelectOrderDistribution, FilterForBoxesNotDistrisbuted },
  mixins: [filterable],
  data() {
    return {
      data: {
        order_id: "",
        quantity: 0,
      },
    };
  },
  watch: {
    box_id_selected: {
      immediate: true,
      handler(id) {
        if (id) {
          this.$store.dispatch(
            "warehouse.boxes_not_distributed_yet.detail.fetch",
            id
          );
          this.data.order_id = "";
          this.data.quantity = 0;
        }
      },
    },
  },
  mounted() {
    if (this.lodash.isEmpty(this.query)) {
      this.$store.dispatch(
        "warehouse.boxes_not_distributed_yet.fetch.if-first-time"
      );
    } else {
      this.$store.dispatch(
        "warehouse.boxes_not_distributed_yet.apply-query",
        this.query
      );
    }
  },
  computed: {
    ...mapGetters({
      boxes_available: "warehouse.boxes_not_distributed_yet.list",
      boxes_available_loading: "warehouse.boxes_not_distributed_yet.loading",
      loading: "warehouse.owning_boxes.loading",
    }),
    box_id_selected() {
      return this.$route.query.box_id;
    },
    box_selected() {
      return this.$store.getters["warehouse.boxes_not_distributed_yet.detail"];
    },
  },
  methods: {
    onDistribute() {
      this.$store
        .dispatch("warehouse.owning_boxes.create", {
          box_id: this.box_id_selected,
          ...this.data,
        })
        .then((data) => {
          this.$router.push({
            path: `/warehouse/internal/owning-boxes/distributed?owning_box_id=${data.id}`,
          });
        });
    },
  },
};
</script>
